<template>
  <div class="pickupp-form card">
    <div class="card-content">
      <div class="form-header">
        {{ $t(`assignment.pickuppForm.formHeader.${externalLogisticsType}`) }}
      </div>
      <div class="order-number">
        {{ task.orderIdentifier }}
      </div>
      <form @submit.prevent>
        <b-field :label="$t('assignment.pickuppForm.formLabels.hub')">
          <b-input
            v-model="hub"
            readonly/>
        </b-field>
        <b-field :label="$t('assignment.pickuppForm.formLabels.deliveryTime')">
          <b-input
            v-model="deliveryTime"
            readonly/>
        </b-field>
        <b-field :label="$t('assignment.pickuppForm.formLabels.customerDetails')">
          <b-input
            v-model="customerDetails"
            readonly/>
        </b-field>
        <b-field :label="$t('assignment.pickuppForm.formLabels.customerAddress')">
          <b-input
            v-model="customerAddress"
            readonly/>
        </b-field>
        <div class="buttons">
          <b-button @click="cancelForm">
            {{ $t('assignment.pickuppForm.actions.cancel') }}
          </b-button>
          <b-button
            type="is-primary"
            @click="confirmForm">
            {{ $t('assignment.pickuppForm.actions.confirm') }}
          </b-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'pickupp-form',
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hub: null,
      deliveryTime: null,
      customerDetails: null,
      customerAddress: null
    }
  },
  computed: {
    ...mapGetters('sessionUser', [
      'features'
    ]),
    externalLogisticsType() {
      if (this.features.useLalamove) {
        return 'lalamove'
      }
      return 'pickupp'
    }
  },
  created() {
    this.hub = this.task.hubShortCode
    this.deliveryTime = this.task.rangeLabel
    this.customerDetails = `${this.task.customerName} • ${this.task.customerContactNumber}`
    this.customerAddress = this.task.deliveryAddress || this.task.pickupPointName
  },
  methods: {
    cancelForm() {
      this.$parent.close()
    },
    confirmForm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.pickupp-form {
  .card-content {
    padding: $space-s $space-m $space-l;

    .form-header,
    .order-number {
      @extend %heading;
    }

    .order-number {
      margin-bottom: $space-l;
      font-weight: normal;
    }

    form {
      .field::v-deep {
        &:not(:last-child) {
          margin-bottom: $space-m;
        }

        label {
          color: $grey;
          @extend %small;
        }

        input {
          @extend %body;

          &[readonly] {
            background-color: $grey-lighter;
          }
        }
      }

      .buttons {
        display: flex;
        margin-top: $space-l;

        > * {
          flex-grow: 1;
        }
      }
    }
  }
}
</style>
